import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tabs, TextInput, Button, Label } from "flowbite-react";
import React, { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toggleAddSpeechLabModal } from "../../../features/home/speechLabSlice";
import { faArrowAltCircleLeft, faCancel, faCirclePlus, faCircleXmark, faCloudUpload, faMinus, faMinusCircle, faPlusCircle, faSave, faUpload } from "@fortawesome/free-solid-svg-icons";
import { HiPencilAlt, HiSearch, HiOutlineGlobe, HiPlusCircle, HiPhotograph, HiMinusCircle, HiXCircle, HiUserGroup, HiArrowCircleLeft, HiArrowCircleRight, HiLockClosed, HiLockOpen } from 'react-icons/hi';
import { useState, useEffect } from "react";
import PhonicCard from "../../PhonicCard";
import ItemWord from "./ItemWord";
import NewPhonicForm from "./NewPhonicForm";



const SpeechLabPopup = () => {
    const dispatch = useDispatch();
    // state show add panel 
    const TABS = {
        MYLAB: "MYLAB",
        GALAXY: "GALAX",
        COMMUNITY: "COMMUNITY",
        TEMPLATE: "TEMPLATE",
    };

    // enum for tab


    const officalPhonics = useSelector(state => state.speechLabReducer.officalPhonics);
    const officalPhonicsGroup = useSelector(state => state.speechLabReducer.officalPhonicsGroup);
    // const communityPhonics = useSelector(state => state.speechLabReducer.communityPhonics);
    const myPhonics = useSelector(state => state.speechLabReducer.myPhonics);
    const searchPhonics = useSelector(state => state.speechLabReducer.searchPhonics);
    const publicPhonics = useSelector(state => state.speechLabReducer.publicPhonics);
    // local data 
    const fileInputRef = useRef(null);
    const [showAddPanel, setShowAddPanel] = React.useState(false);
    const [currentTab, setCurrentTab] = React.useState(TABS.GALAXY);
    const [currentPhonic, setCurrentPhonic] = React.useState(null);
    const [_officailPhonics, setOfficalPhonics] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const role = useSelector((state) => state.userReducer.user.role);
    const [currentPhonicGroup = {
        title: "",
        description: "",
        tags: [],
        cover: "",
        phonic_ids: [],
        phonics: []
    }, setCurrentPhonicGroup] = useState();
    const [newPhonicForm, setNewPhonicForm] = useState(null);

    useEffect(() => {
        // if searchTerm is empty set officalPhonics to officalPhonics
        // searchTerm remove space

        if (searchTerm.replace(/\s/g, '') == '') {
            // dispatch home set search phonics to empty array
            dispatch({ type: 'speechLab/setSearchPhonics', payload: [] });
        } else {
            const delayDebounceFn = setTimeout(() => {
                console.log(searchTerm)
                dispatch({ type: 'SEARCH_OFFICAL_PHONICS', payload: searchTerm });
            }, 3000)
            return () => clearTimeout(delayDebounceFn)
        }
    }, [searchTerm])
    // when prop showAddSpeechLabModal change to true  call saga event INIT_SPEECH_LAB
    React.useEffect(() => {
        dispatch({ type: 'INIT_SPEECH_LAB' });
    }, []);

    React.useEffect(() => {
        console.log("officalPhonics change");
        setOfficalPhonics(officalPhonics);
    }, [officalPhonics]);

    // click show add panel scroll to top then show add panel
    const handleShowAddPanel = () => {
        window.scrollTo(0, 0);
        setShowAddPanel(!showAddPanel);
    };
    const handleClose = () => {
        console.log("close")
        dispatch(toggleAddSpeechLabModal());

    };

    const handleUploadFileClick = () => {
        // Click the hidden file input element when the div is clicked
        fileInputRef.current.click();

    };

    const handleFileChange = (event) => {
        const selectedFile = event.target.files[0];
        // Handle the selected file here (e.g., upload it to a server)

        if (selectedFile) {
            // Use URL.createObjectURL to create a temporary URL for the selected image
            setCurrentPhonicGroup({
                ...currentPhonicGroup,
                cover: URL.createObjectURL(selectedFile)
            });
        }
    };
    // next page 
    const handleNextPage = () => {
        console.log("next page");
        // dispatch fetch offical phonics with page
        dispatch({ type: 'FETCH_OFFICAL_PHONICS', payload: { page: currentPage + 1 } });
        setCurrentPage(currentPage + 1);
    }

    // previous page
    const handlePreviousPage = () => {
        console.log("previous page");
        // dispatch fetch offical phonics with page
        dispatch({ type: 'FETCH_OFFICAL_PHONICS', payload: { page: currentPage - 1 } });
        setCurrentPage(currentPage - 1);
    }

    // add phonic to current group phonic list
    const handleAddPhonic = (phonic) => {
        console.log("add phonic");
        // if phonic with same id is in current group phonic list return
        if (currentPhonicGroup.phonic_ids.find((item) => item._id === phonic._id)) return;

        setCurrentPhonicGroup({ ...currentPhonicGroup, phonic_ids: [...currentPhonicGroup.phonic_ids, phonic] });
        // dispatch({ type: 'speechLab/setOfficalPhonics', payload: data });
    }

    // remove phonic from current group phonic list
    const handleRemovePhonic = (phonic) => {
        console.log("remove phonic");
        // find index of phonic in current group phonic list
        const index = currentPhonicGroup.phonic_ids.findIndex((item) => item._id === phonic._id);

        // remove phonic from current group phonic list by setCurrentPhonicGroup
        setCurrentPhonicGroup({ ...currentPhonicGroup, phonic_ids: [...currentPhonicGroup.phonic_ids.slice(0, index), ...currentPhonicGroup.phonic_ids.slice(index + 1)] });

    }


    const [currentPage, setCurrentPage] = useState(1);
    const onPageChange = (page) => setCurrentPage(page);

    const onCreatedPhonicGroup = () => {
        console.log("onCreatedPhonicGroup");
        // dispatch create phonic group
        // phonic ids from current group phonic list
        const ids = currentPhonicGroup.phonic_ids.map((item) => item._id);
        // if ids is not array return
        if (!Array.isArray(ids)) {
            console.log("ids is not array");
            return;
        };

        dispatch({
            type: 'CREATE_PHONIC_GROUP', payload: {
                title: currentPhonicGroup.title,
                phonic_ids: ids,
                cover: currentPhonicGroup.cover,
                file: fileInputRef.current.files[0]
            }
        });
    }

    // handle text input change title of current group
    const handleTitleChange = (e) => {
        console.log("handleTitleChange");
        setCurrentPhonicGroup({ ...currentPhonicGroup, title: e.target.value });
    }

    // handle open new phonic form
    const handleOpenNewPhonicForm = () => {
        console.log("handleOpenNewPhonicForm");
        setNewPhonicForm({
            word_ch: "",
            word_image: "",
            tags: [],
        });
    }

    return (
        <>
            <div className="fixed z-20 inset-0 bg-amber-50 w-full h-full flex justify-between">
                <div className="w-2/5 bg-white h-full  overflow-y-auto">
                    <div className="flex w-full flex-col">
                        <div className="flex flex-col justify-between lg:flex-row">
                            <div className=" bg-gray-200 p-4">
                                <div onClick={handleUploadFileClick}
                                    className="relative h-32 aspect-[1/1] bg-slate-400 rounded-md hover:bg-cyan-300 cursor-pointer shadow-md">
                                    {currentPhonicGroup.cover && <img className="absolute w-full h-full object-cover rounded-md" src={currentPhonicGroup.cover} alt="" />}
                                    {!currentPhonicGroup.cover && <div className="absolute w-full h-full flex justify-center items-center">
                                        <HiPhotograph className="text-white text-6xl" />
                                    </div>}
                                </div>
                                <input type="file" accept="image/*" ref={fileInputRef} className="hidden" onChange={handleFileChange} />
                            </div>
                            <div className=" flex flex-col w-full justify-evenly bg-gray-200 p-4">
                                <TextInput
                                    placeholder="NEW SPEECH LAB NAME"
                                    size='sm'
                                    type="text"
                                    rightIcon={HiLockClosed}
                                    className=" w-auto"
                                    onChange={handleTitleChange}
                                />
                                <Button onClick={onCreatedPhonicGroup} >
                                    <p>
                                        Create Speech Lab
                                    </p>
                                </Button>
                            </div>
                        </div>
                    </div>
                    <div className="overflow-y-auto h-full">
                        <ul className="my-2 pl-2 pr-2 space-y-1 `">
                            {currentPhonicGroup.phonic_ids && currentPhonicGroup.phonic_ids.map((item, index) => (
                                <li key={item._id}
                                >
                                    <a className="group flex items-center rounded-lg bg-green-500 p-3 text-base font-bold text-gray-900 hover:bg-lime-600 hover:shadow dark:bg-gray-600 dark:text-white dark:hover:bg-gray-500"
                                        href="#"
                                    >
                                        <span className="ml-3 flex-1 whitespace-nowrap">
                                            {index + 1}
                                        </span>
                                        <span className="ml-3 flex-1 whitespace-nowrap" >
                                            {item.word_ch}
                                        </span>

                                        <span onClick={() => handleRemovePhonic(item)} key={`${index}`} className="hover:text-red-700 hover:bg-orange-700 ml-3 inline-flex items-center justify-center rounded bg-gray-200 px-2 py-0.5 text-xs font-medium text-gray-500 dark:bg-gray-700 dark:text-gray-400">
                                            <p>
                                                {HiMinusCircle}
                                            </p>
                                        </span>

                                    </a>
                                </li>
                            ))}
                        </ul>

                    </div>
                </div>
                <div className="w-3/5 bg-gray-100 h-full shadow-lg">
                    <div className="flex w-full flex-col bg-white">
                        <div class="sm:hidden">
                            <label for="tabs" class="sr-only">Select your country</label>
                            <select id="tabs" onVolumeChange={(event) => setCurrentTab(event.target.value)} class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                                { role !== 'admin' && <option value={TABS.MYLAB}  >MY LAB</option>}
                                <option value={TABS.GALAXY}>GALAXY WORDS</option>
                              { role !== 'admin' &&   <option value={TABS.COMMUNITY}> <HiUserGroup></HiUserGroup> COMMUNITY SHARED</option>
                              }  
                               { role !== 'admin' && <option value={TABS.TEMPLATE} >TEMPLATES</option>}
                            </select>
                        </div>
                        <ul className="hidden text-sm font-medium text-center text-gray-500 divide-x divide-gray-200 shadow sm:flex dark:divide-gray-700 dark:text-gray-400">
                   {     role !== 'admin' &&       <li className={currentTab == TABS.MYLAB ? "w-full bg-gray-100" : "w-full"} onClick={() => setCurrentTab(TABS.MYLAB)} >
                                 <a href="#" class="flex flex-row w-full p-4   justify-center hover:text-gray-700  focus:ring-4 focus:ring-blue-300 focus:outline-none dark:hover:text-white dark:bg-gray-800 dark:hover:bg-gray-700">
                                   <span className="mr-2">
                                   <HiPencilAlt className=" text-lg" /> 
                                    </span>
                                    { role == 'admin' ? "OFFICIAL WORDS"  : "MY LAB"}
                                </a>
                            </li>}
                            <li className={currentTab == TABS.GALAXY ? "w-full bg-gray-100" : "w-full"} onClick={() => setCurrentTab(TABS.GALAXY)} >
                            <a href="#" class="flex flex-row justify-center w-full p-4  hover:text-gray-700  focus:ring-4 focus:ring-blue-300 focus:outline-none dark:hover:text-white dark:bg-gray-800 dark:hover:bg-gray-700">
                                   <span className="mr-2">
                                   <HiOutlineGlobe className=" text-lg" /> 
                                    </span>  GALAXY WORDS
                                </a>
                            </li>
                       { role !== 'admin' &&    <li className={currentTab == TABS.COMMUNITY ? "w-full bg-gray-100" : "w-full"} onClick={() => setCurrentTab(TABS.COMMUNITY)} >
                             
                                <a href="#" class="flex flex-row justify-center w-full p-4  hover:text-gray-700  focus:ring-4 focus:ring-blue-300 focus:outline-none dark:hover:text-white dark:bg-gray-800 dark:hover:bg-gray-700">
                                   <span className="mr-2">
                                   <HiUserGroup className=" text-lg" /> 
                                    </span> COMMUNITY SHARED
                                </a>
                            </li>}
                       {   role !== 'admin' &&     <li className={currentTab == TABS.TEMPLATE ? "w-full bg-gray-100" : "w-full"} onClick={() => setCurrentTab(TABS.TEMPLATE)} >
                                <a href="#" class="inline-block w-full p-4 rounded-r-lg hover:text-gray-700 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:hover:text-white dark:bg-gray-800 dark:hover:bg-gray-700">TEMPLATES</a>
                            </li>}
                        </ul>
                        {currentTab == TABS.GALAXY && <div className="w-full pt-3 pl-3 pr-3 bg-gray-100">
                            <TextInput
                                // onChange={(e) => setSearchTerm(e.target.value)}
                                onChange={(e) => setSearchTerm(e.target.value.toString())}
                                placeholder="Search"
                                rightIcon={HiSearch}
                                type="text"
                            />
                        </div>}
                        {currentTab == TABS.MYLAB && <div className="w-full pt-3 pl-3 pr-3 bg-gray-100 flex justify-between">
                            <div>
                                <button onClick={handleOpenNewPhonicForm}
                                type="button" className="text-white bg-green-600 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center mr-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                                    <HiPlusCircle className="mr-2" />
                                  NEW WORD
                                </button>
                            </div>
                        </div>}
                        {currentTab == TABS.GALAXY && <div className="w-full pt-3 pl-3 pr-3 bg-gray-100 flex justify-between">
                            <div>
                                <button onClick={handleOpenNewPhonicForm}
                                type="button" className="text-white bg-green-600 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center mr-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                                    <HiPlusCircle className="mr-2" />
                                    ADD OFFICIAL WORD
                                </button>
                            </div>
                        </div>}
                    </div>
                    {currentTab == TABS.GALAXY &&
                        <>
                            <div className="overflow-y-auto h-full bg-gray-100">
                                <ul className="my-2 pl-2 pr-2 space-y-1 `">
                                    {searchPhonics.length <= 0 && _officailPhonics.map((item, index) => (
                                        <ItemWord key={item._id} item={item} setCurrentPhonic={setCurrentPhonic} currentPhonicGroup={currentPhonicGroup} handleAddPhonic={handleAddPhonic} index={index} />
                                    ))}
                                    {searchPhonics.length > 0 && searchPhonics.map((item, index) => (
                                        <ItemWord key={item._id}  item={item} setCurrentPhonic={setCurrentPhonic} currentPhonicGroup={currentPhonicGroup} handleAddPhonic={handleAddPhonic} index={index} />
                                    ))}
                                </ul>
                            </div>
                        </>
                    }
                    
                    {currentTab == TABS.MYLAB &&
                        <>
                            <div className="overflow-y-auto h-full bg-gray-100">
                                <ul className="my-2 pl-2 pr-2 space-y-1 `">
                                    {role !== 'admin' && myPhonics.map((item, index) => (
                                        <ItemWord key={item._id} showPublic={true}  setCurrentPhonic={setCurrentPhonic} item={item} currentPhonicGroup={currentPhonicGroup} handleAddPhonic={handleAddPhonic} index={index} />
                                    ))}
                                </ul>
                            </div>
                        </>
                    }
                    {currentTab == TABS.COMMUNITY &&
                        <div className="overflow-y-auto h-full bg-gray-100">
                                    <ul className="my-2 pl-2 pr-2 space-y-1 `">
                                    {publicPhonics.map((item, index) => (
                                        <ItemWord key={item._id}  setCurrentPhonic={setCurrentPhonic} item={item} currentPhonicGroup={currentPhonicGroup} handleAddPhonic={handleAddPhonic} index={index} />
                                    ))}
                                </ul>
                        </div>
                    }
                    {currentTab == TABS.TEMPLATE &&
                        <div className="overflow-y-auto h-full bg-gray-100 flex justify-center items-center">
                            <span className="text-gray-500 text-2xl font-bold">
                                Coming Soon
                            </span>
                        </div>

                    }
                    <div className="absolute bottom-5 right-10 flex flex-row rounded-lg bg-slate-50 shadow-xl pt-2 pb-2 pl-2 pr-2">
                        <HiArrowCircleLeft onClick={handlePreviousPage} className="text-4xl text-gray-500 hover:text-gray-700 cursor-pointer" />
                        <span className=" text-gray-500 hover:text-gray-700 cursor-pointer p-2 pt-1  font-extrabold">
                            {currentPage}
                        </span>
                        <HiArrowCircleRight onClick={handleNextPage} className="text-4xl text-gray-500 hover:text-gray-700 cursor-pointer" />
                    </div>
                    <div
                        onClick={handleClose}
                        className="absolute top-2 right-2 flex flex-row rounded-lg shadow-xl pt-2 pb-2 pl-2 pr-2">
                        <HiXCircle className="text-2xl text-gray-500 hover:text-red-700 cursor-pointer" />
                    </div>
                </div>
            </div >
            {currentPhonic &&
                // PhonicCard with props currentPhonicWord and setCurrentPhonicWord
                <PhonicCard currentPhonic={currentPhonic} setCurrentPhonic={setCurrentPhonic} />
            }
            {
                newPhonicForm &&
                <NewPhonicForm newPhonicForm={newPhonicForm} setNewPhonicForm={setNewPhonicForm} />
            }
        </>
    )
}

export default SpeechLabPopup;
