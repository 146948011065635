import { createSlice, createReducer } from "@reduxjs/toolkit";

const speechPlaySlice = createSlice(
    {
        name: 'speechPlay',
        initialState: {
            gameManager: {
                showScoreView : "repare", // repare, question, score, final_socre, recording
                isExploding : false,
                progress : 0,
                currentQuestion : -1,
                timeLimit : 0,
                progressInterval : null,
                recognizer: null,
            },
            dataManager: {
                speechLabData : {},
                recordedData : [],
                totalScore : 0,
                currentAudio : null,
            }
        },
        reducers:  {
        SET_RECOGNIZER: (state, action) => {
            state.gameManager.recognizer = action.payload;
        },
        RESET: (state, action) => {
            console.log("SET_SPEECH_LAB_DATA in speechPlayReducer");
            // reset all data
            state.gameManager.isExploding = false;
            state.gameManager.progress = 0;
            state.gameManager.currentQuestion = -1;
            state.gameManager.loading = true;
            state.dataManager.speechLabData = action.payload;
            state.dataManager.recordedData = [];
            state.dataManager.totalScore = 0;
            state.gameManager.showScoreView = "repare";

        },
        SET_SPEECH_LAB_DATA: (state, action) => {
            console.log("SET_SPEECH_LAB_DATA in ");
            // set data manager.speechLabData to action.payload
            state.dataManager.speechLabData = action.payload;
            // call SPEECH_PLAY_START 
            state.gameManager.showScoreView = "question";
            state.gameManager.currentQuestion = 0;
            state.gameManager.progress = 0;

        },
        SET_SPEECH_LAB_SCORE: (state, action) => {
            console.log("SET_SPEECH_LAB_SCORE in speechPlayReducer");
                // set push data to data manager.recoredData to action.payload
            if(action.payload != null && action.payload.NBest  && action.payload.NBest.length > 0 ){
                console.log(action.payload.NBest);
                state.dataManager.recordedData.push(action.payload);
                // add score to data manager.totalScore
                state.dataManager.totalScore = state.dataManager.totalScore + action.payload.NBest[0].Words[0].AccuracyScore;
                // move to display score
                state.gameManager.showScoreView = "score";
            }else{
                console.log("Fail to get score")
                // dispatch({ type: sagaActions.MESSAGE_POPUP, payload: { tittle : "Please TRY AGAIN" ,message: "Something", type: "error" } });
            }
        },
        SPEECH_PLAY_NEXT_QUESTION: (state, action) => {
            console.log(`SPEECH_PLAY_NEXT_QUESTION [SPR] : ${state.gameManager.currentQuestion} / ${state.dataManager.speechLabData.phonics.length}`);
            // move to next question if last question then move to final score
            state.gameManager.currentQuestion = state.gameManager.currentQuestion + 1;
            state.gameManager.progress = state.gameManager.currentQuestion / state.dataManager.speechLabData.phonics.length * 100;
            if (state.gameManager.currentQuestion >= state.dataManager.speechLabData.phonics.length) {
                state.gameManager.showScoreView = "final_score";
            }else{
                state.gameManager.showScoreView = "question";
            }
        },
        SPEECH_PLAY_EXPLODE: (state, action) => {
            state.gameManager.isExploding = action.payload;
        },
        SPEECH_PLAY_SCORE: (state, action) => {
            state.gameManager.showScoreView = "score";
            state.dataManager.recordedData.push(action.payload);
            state.dataManager.totalScore = state.dataManager.totalScore + action.payload.score;
        },
        SPEECH_PLAY_STOP: (state, action) => {
            state.gameManager.showScoreView = "final_score"; 
        },
        SPEECH_PLAY_REPLAY: (state, action) => {
            state.gameManager.showScoreView = "repare";
            state.gameManager.isExploding = false;
            state.gameManager.progress = 0;
            state.gameManager.currentQuestion = -1;
            state.gameManager.loading = false;
            state.dataManager.speechLabData = {};
            state.dataManager.recoredData = [];
            state.dataManager.totalScore = 0;
        },
        SPEECH_PLAY_SET_AUDIO: (state, action) => {
            state.dataManager.currentAudio = action.payload;
        }
    }
    }   
)

export const {
    SET_SPEECH_LAB_DATA,
    BEGIN_RECORDING,
    SPEECH_PLAY_EXPLODE,

} = speechPlaySlice.actions;
 
export default speechPlaySlice.reducer;


