import React from "react";
import { motion, AnimatePresence } from "framer-motion";
import { useSelector } from 'react-redux';
const SpeechLabImageCardComponent = ({ showScoreView }) => {
  const {gameManager, dataManager} = useSelector(state => state.speechPlayReducer);
   


  return (
    <AnimatePresence>
{  gameManager.showScoreView == "question" && gameManager.currentQuestion >= 0 &&  (<motion.div
      className="w-1/2 h-full p-4"
      initial={{ x: "100%", opacity:  0 }} // Initial position, hidden to the right
      animate={{ x: 0, opacity: 1}} // Final position, move to the left (in view)
      transition={{ duration: 0.5, delay: 0.35 }} // Animation duration in seconds
      // exit={{ x: "-100%", opacity: 0 } }
    >
      <motion.div 
             whileTap={{ scale: 0.8 }}
             whileHover={{ scale: 1.05 }}
          className="h-full flex flex-col text-center justify-center rounded-lg">
          <img src={dataManager.speechLabData.phonics[gameManager.currentQuestion].img_uri} alt={dataManager.speechLabData.phonics[gameManager.currentQuestion].word_ch} />
      </motion.div>
    </motion.div>)}
    </AnimatePresence>
  );
};

export default SpeechLabImageCardComponent;