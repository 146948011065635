import React, { useEffect, useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { useSelector } from 'react-redux';


const WordCardComponent = ({showScoreView}) => {
  const {gameManager, dataManager} = useSelector(state => state.speechPlayReducer);
  // when show calculate 
  const [text, setText] = useState("");
  useEffect(() => {
   
    if((gameManager.currentQuestion < 0  )  ) return;
    // loop through all dataManager.speechLabData.phonics[gameManager.currentQuestion].phonicSound
    // combine all text into one string > set to text
    let _text = "";
    dataManager.speechLabData.phonics[gameManager.currentQuestion]?.phonicSound.forEach(element => {
      _text += ` ${element.text}`;
    });
    setText(_text);
  }
  , [gameManager.currentQuestion]);
    
  return (
    <AnimatePresence>
    {  gameManager.showScoreView == "question" &&  gameManager.currentQuestion >= 0 &&
    (<motion.div
      className="w-1/2 h-full p-4"
      initial={{ x: "100%", opacity: 0 }} // Initial position, hidden to the right
      animate={{ x: 0, opacity: 1}}  // Final position, move to the left (in view)
      transition={{ duration: 0.6, delay: 0.35 }} // Animation duration in seconds
      // exit={{ x: "-100%" }} 
    >
      <div className="h-full flex flex-col text-center justify-center rounded-lg">
        <h5 className=" text-9xl font-bold leading-none text-gray-900 dark:text-white">
        {dataManager.speechLabData.phonics[gameManager.currentQuestion].word_ch}
        </h5>
        <p className="text-xl font-medium mt-8 text-gray-500 dark:text-gray-400">
        {text}
        </p>
      </div>
    </motion.div>)
    }
    </AnimatePresence>
  );
};

export default WordCardComponent;