
import React, { useState } from 'react';
import { Dropdown } from 'flowbite-react';
import { useSelector, useDispatch } from 'react-redux';
import {Html5QrcodeScanner} from "html5-qrcode";
import { useEffect } from 'react';


const AddExistStudentPopup = ( {setIsShowExistStudentPopup}) => {
    
    const dispatch = useDispatch();
    const currentStudyGroup = useSelector(state => state.homeReducer.currentStudyGroup);
    const [state, setState] = useState({
        student_code: "",
        studyGroup: currentStudyGroup
    });
    const studyGroups = useSelector(state => state.homeReducer.studyGroups);
    const selectGroup = (index) => {
        console.log("selectGroup " + studyGroups[index]);
        setState({
            ...state,
            studyGroup: studyGroups[index]
        }); 
    }

    const onAddExistStudent = () => {
        console.log("onAddExistStudent");
        // if state.studyGroup is null or  student is null dispatch popup  message "Please select study group and student code" 
        // else dispatch({type: 'ADD_EXIST_STUDENT_BY_COD/E', payload: {student_code: state.student_code, study_group_id: state.studyGroup._id}});
        if (state.studyGroup == null ) {
            console.log("Please select study group");
            dispatch({type: 'main/setPopup', payload: {message: "Please select study group and student code", type: "error"}});
        } 
        else if (state.student_code == "") {
            console.log("Please input student code");
            dispatch({type: 'main/setPopup', payload: {message: "Please input student code", type: "error"}});
        }
        else {
            dispatch({type: 'ADD_EXIST_STUDENT_BY_CODE', payload: {student_code: state.student_code, study_group_id: state.studyGroup._id}});
            setIsShowExistStudentPopup(false);
        }
    }

        


    return ( 
        <div className="fixed z-10 inset-0 bg-slate-700 bg-opacity-80 w-full h-full outline flex justify-center items-center">
            <div className='flex justify-center items-center flex-col gap-5 bg-white rounded-xl shadow pl-5 pr-5 pb-5'>
                <div className="relative">
                    <div className='flex-col justify-end items-end flex '>
                        <button 
                            onClick={()=>{setIsShowExistStudentPopup(false);}}
                            type="button" className="  text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white" data-modal-hide="authentication-modal">
                            <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                            <span className="sr-only">Close </span>
                        </button>
                    </div>
                    <div className="flex justify-center items-center flex-col gap-2">
                        <label for="pin" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                            Add students to 
                        </label>
                        <Dropdown label={(state.studyGroup) ? state.studyGroup?.title : "Select Study Group"} 
                               placement="right" >
                                 <Dropdown.Item >
                                    none
                                </Dropdown.Item>
                            {studyGroups.map((studyGroup, index ) =>      
                                <Dropdown.Item key={index} onClick={()=>{ selectGroup(index); console.log(studyGroup);}} >
                                    {studyGroup.title}
                                </Dropdown.Item>
                            )}
                        </Dropdown>
                        <input type="text"
                            value={state.student_code}
                            onChange={(e)=>{setState({...state, student_code: e.target.value})}}
                            placeholder="Student Code" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white" />
                        <button 
                            onClick={onAddExistStudent}
                            className="w-full text-white bg-indigo-700 hover:bg-indigo-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                            Add Student
                        </button>
                    </div>
                </div>
            </div>
        </div>
        );
}


export default AddExistStudentPopup;