// Reducers and actions for page routing
// --------------------------------------------------------

import { fa } from '@faker-js/faker';
import { createSlice } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import { TEACHER_GET_STUDY_GROUP } from './studyGroupRuducer';




const URL = "";
const initialState = {
    page: 'login',
    popupMessage: {
        show: false,
        title : "",
        message : "",
        color : "info"
    },
    showPopupMessage: false,
    loading: false,
};

const globalReducer = createSlice({
    name: 'main',
    initialState,
    reducers: {
        setPage: (state, action) => {
            state.page = action.payload.page;

        },
        setPopup: (state, action) => {
            state.showPopupMessage = false;
            state.popupMessage = action.payload;
            state.showPopupMessage = true;
        },
        closePopupEvent: (state, action) => {
            
            if (state.showPopupMessage) {
                state.popupMessage = {show: false, title : "", message : "", color : "info"};  
                state.showPopupMessage = false;
            }
        },
        setLocation: (state, action) => {
            // state.page = action.payload;
        },
        setLoading: (state, action) => {
            state.loading = action.payload;
        },
    }
});
export const { setPage ,setPopup, closePopupEvent, closeWindow, setLocation} = globalReducer.actions;
export default globalReducer.reducer;
