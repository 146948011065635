// Date: 07/09/23
// Creator: 
// Description: Final score card component
//
import React, { useEffect, useState } from 'react';
import { motion, AnimatePresence } from "framer-motion";
import PhonicScoreCard from './PhonicScoreCard';
import FinalWordScoreCard from './FinalWordScoreCard';
import CircularProgress from './CircleProgress';
import { useSelector } from 'react-redux';


const FinalScoreCard = ({ showScoreView }) => {
    const { gameManager, dataManager } = useSelector(state => state.speechPlayReducer);
    const [height, setHeight] = useState(null);
  
    useEffect(() => {
        const _height = window.innerHeight - 56;
        setHeight(_height);
  
    }, [ ]);
    // ever time showScoreView change to final_score

    return (
        <AnimatePresence>
            {
                gameManager.showScoreView == "final_score" && <motion.div
                    className="w-full flex-1 flex justify-center items-start p-5 pt-8"
                    style={{ height: `${height}px` }}
                    whileHover={{ scale: 1.01 }}
                    initial={{ y: "100%", opacity: 0 }} // Initial position, hidden to the right
                    animate={{ y: 0, opacity: 1 }} // Final position, move to the left (in view)
                    transition={{ duration: 0.5, delay: 0.4 }} // Animation duration in seconds
                // exit={{ x: "-100%", opacity: 0 }}
                >
                    <div className="w-3/5 max-h-full flex shadow-2xl  flex-col justify-center items-center  bg-slate-100  bg-[url('https://s3-ap-southeast-1.amazonaws.com/rgk-images/bg_nana_space_game.png')] bg-opacity-30 rounded-xl  text-center ">
                        <div className='pt-2 flex flex-col'>
                            <CircularProgress delay={0.5} duration={3.5} score={dataManager.totalScore/dataManager.recordedData.length} />
                            <span className='pt-2 text-white'>  total score of {dataManager.recordedData.length} words </span>
                        </div>
                        <ul className="font-bold felx flex-1 flex-grow overflow-y-auto overflow-x-hidden w-full rounded-xl">
                            {dataManager.recordedData.map((word, index) => {
                                return <FinalWordScoreCard image_uri={dataManager.speechLabData.phonics[index].img_uri} word={word.DisplayText} score={word.NBest[0].AccuracyScore} />
                            })}

                        </ul>
                    </div>
                </motion.div>}

        </AnimatePresence>
    );
}

export default FinalScoreCard;