import React, { useEffect, useState, useRef } from "react";
import { motion, useAnimation } from "framer-motion";
import chatIcon from "../../assets/icon_chat.png";
import micIcon from "../../assets/mic_icon.png";
import { HiOutlineMail, HiArrowRight, HiXCircle } from 'react-icons/hi'
import { useSelector,useDispatch } from 'react-redux';
import { textToSpeech } from "../../services/speech_service";
import RecordRTC from "recordrtc";
import { getAuthHeader } from "../../services/auth_helper";
import { setPopup } from "../../features/globalReducer";
import { StereoAudioRecorder } from "recordrtc";
import config from "../../config";

const SpeechLabController = ({ setShowScoreView, showScoreView, percentage, setPercentage }) => {
    const { gameManager, dataManager } = useSelector(state => state.speechPlayReducer);
    const [temp_recognizer, setTemp_recognizer] = useState(undefined);
    const [timer, setTimer] = useState(undefined);
    const dispatch = useDispatch();
    const controls = useAnimation();
    const controlsLeft = useAnimation();
    const [percentageRight, setPercentageRight] = useState(100);
    const [percentageLeft, setPercentageLeft] = useState(0);
    // Trigger the animation when the component mounts
    const [beginRecording, setBeginRecording] = useState(false);
    // let recognizer ;
    const [audioBlob, setAudioBlob] = useState(null);
    const recorderRef = useRef(null);
    // list of audioBlob
    const [ audioBlobList, setAudioBlobList ] = useState([]);
    // list of recorder 
    const [ recorderList, setRecorderList ] = useState([]);

    useEffect(() => {
        console.log("SpeechLabController useEffect");
        navigator.mediaDevices
            .getUserMedia({ audio: true })
            .then(async (stream) => {
                const options = {
                    recorderType: StereoAudioRecorder,
                    mimeType: 'audio/wav'
                };
                // set loading
                dispatch({ type: "main/setLoading", payload: true });
                const records = [];
                for (let i = 0; i < dataManager.speechLabData.phonics.length; i++) {
                    const recorder = new RecordRTC(stream, options);
                    console.log(`${i} recorder: ${recorder}`);
                    records.push(recorder);
                    // if last one
                    if (i == dataManager.speechLabData.phonics.length - 1) {
            
                        setRecorderList(records);
                        // all recorders start recording
                        
                        // set loading
                        dispatch({ type: "main/setLoading", payload: false });
                    }
                }
                // all recorders start recording
                
             
         
            })
            .catch((err) => console.error("Error accessing microphone:", err));
    }, [dataManager.speechLabData]);



    const startRecording = async () => {
        if(recorderList[gameManager.currentQuestion]){
            await recorderList[gameManager.currentQuestion].startRecording();
            setBeginRecording(true);
        } else {    
            console.log("recorderList[gameManager.currentQuestion] is null");
        }
        // navigator.mediaDevices
        //     .getUserMedia({ audio: true })
        //     .then(async (stream) => {
        //         const options = {
        //             // mimeType: "audio/wav",
        //             // sampleRate: 16000,
        //             recorderType: StereoAudioRecorder,
        //             mimeType: 'audio/wav'
        //         };
         
        //                 // To stop the stream when you're done with it or want to release the microphone:
        //                 // You can call the stop() method on each track within the stream:
        //                 // stream.getTracks().forEach(track => track.stop());

        //                 // After stopping the tracks, you might also want to release the reference to the MediaStream.
                   

        //         const recorder = new RecordRTC(stream, options);
        //         // recorderRef.current = recorder;
        //         // recorder.startRecording();
        //         setRecorderList([...recorderList, recorder]);
        //         // recorder.startRecording();
        //         await recorderList[gameManager.currentQuestion].startRecording();
            
            
        //         setBeginRecording(true);
        //     })
        //     .catch((err) => console.error("Error accessing microphone:", err));
    };

    const stopRecording = async () => {
        // set loading 
        dispatch({ type: "main/setLoading", payload: true });
        const recorder =  recorderList[gameManager.currentQuestion];   //recorderRef.current;
        if (recorder) {
            await recorder.stopRecording(async () => {
                // setAudioBlob(await recorder.getBlob());
                setAudioBlobList([...audioBlobList, await recorder.getBlob()]);
                const audioElement = new Audio(recorder.toURL());
                audioElement.play();
                const data = await uploadAudio();
                // set loading


                dispatch({ type: "main/setLoading", payload: false });
                // set score record 
                if (data != null) {
                    dispatch({ type: "speechPlay/SET_SPEECH_LAB_SCORE", payload: data });
             
                } else {
                    //dispatch type main/setPopup  "TRY AGAIN"
                    dispatch(setPopup({ title: "TRY AGAIN", message: `microphone issue`, color: "red" }));
                }
                // recorder reset
                // close navigator mediaDevices 
                
            });
            // stop  navigator mediaDevices
            // setAudioBlob(null);

        }
        // play sound from blob


        setBeginRecording(false);
    };

    const uploadAudio = async () => {
        console.log("uploadAudio");
        // const header = getAuthHeader();
        const formData = new FormData();

        formData.append("audio", audioBlobList[audioBlobList.length - 1]); // or gameManager.currentQuestion
        formData.append("reference_text", dataManager.speechLabData.phonics[gameManager.currentQuestion].word_ch);

        const response = await fetch(`${config.URI}/api/speech/pronunciation`, {
            method: "POST",
            body: formData,
            headers: {
                // Authorization: header.Authorization,
                // "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
            }
        });
        if (response.status == 200) {
            const data = await response.json();
            console.log(`data: ${data}`);
            console.log(data);
            // check if Duration is 0 
            if (data.Duration == 0) {
                // dispatch popup message "TRY AGAIN"
                return null;
            }
            return data;
        } else {
            // dispatch popup message "TRY AGAIN"
            return null;
        }

    };

    const onTimeerUpdate = (percentageDecreasePerSecond) => {
        // time in seconds to percaentage by 100
        console.log(percentageLeft);
        if (percentageRight > 0) {
            setPercentageLeft(percentageLeft + 1);
            setPercentageRight(percentageRight - 1);

        } else {
            setPercentageLeft(0);
            setPercentageRight(100);
        }
        console.log(`percentageLeft: ${percentageLeft} : percentageRight: ${percentageRight}`);

    }

    //use effect 
    useEffect(() => {
        onTimeerUpdate(dataManager.timeLimit);
    }, [gameManager.timeLimit]);



    const onBeginRecording = async () => {
        console.log("onBeginRecording");
        if (!beginRecording) {
            // setBeginRecording(true);
            // await sttFromMic();
            startRecording();
        } else {
            // setBeginRecording(false);
            // clear time out 
            // clearTimeout(timer);
            // onStopRecognize();
            stopRecording();
        }
    }

    const onHandleNext = () => {
        // dispatch event to speechPlay/SPEECH_PLAY_NEXT_QUESTION 
        dispatch({ type: "speechPlay/SPEECH_PLAY_NEXT_QUESTION" });
    }


    // useeffect
    useEffect(() => {

        // Animate the progress bar when the percentage changes
        controls.start({ opacity: 1, y: 0 });

    }, []);

    // on showScoreView change
    // if showScoreView == "score" then show the progress bar = 0
    // if showScoreView == "final_score" then show the progress bar = 0 
    // if showScoreView == "question" then show the progress bar = 100
    useEffect(() => {
        if (showScoreView == "score") {
            setPercentageLeft(100);
            setPercentageRight(0);
        } else if (showScoreView == "final_score") {
            setPercentageLeft(100);
            setPercentageRight(0);
        } else if (showScoreView == "question") {
            setPercentageLeft(0);
            setPercentageRight(100);
        }
    }
        , [showScoreView]);

    const onPlaySoundClick = async () => {
        // play sound
        const sound = await textToSpeech(dataManager.speechLabData.phonics[gameManager.currentQuestion].word_ch);
        const audioUrl = URL.createObjectURL(sound);
        const audioElement = new Audio(audioUrl);
        audioElement.play();
    }

    return (
        <div className="absolute bottom-0 left-0 right-0 flex justify-center ">
            <div className="flex flex-col w-full ">
                <motion.div
                    className={showScoreView == "final_score" ?
                        "flex felx-row space-x-10 justify-end pr-8 items-center"
                        : "flex felx-row space-x-10 justify-center items-center"}
                    initial={{ opacity: 0, y: 50 }} // Initial position (hidden and moved down)
                    animate={controls}
                    transition={{ duration: 0.5, delay: 0.75 }} // Animation duration and delay
                >
                    {
                        gameManager.showScoreView == "question" &&
                        <motion.div
                            onClick={onPlaySoundClick}
                            className="flex justify-center"
                            whileHover={{ scale: 1.1 }}
                            // enter from below the screen
                            initial={{ y: 50 }}
                            animate={{ y: 0 }}
                            // exit to below the screen
                            exit={{ y: 50, delay: 0.5 }}
                        >
                            <div className="w-16 h-16 p-2 hover:bg-lime-500 cursor-pointer  bg-gray-200 rounded-full shadow-md flex justify-center items-center">
                                <img

                                    src={chatIcon}
                                    alt="Face"
                                />
                            </div>
                        </motion.div>

                    }
                    {
                        gameManager.showScoreView == "question" && <motion.div
                            onClick={onBeginRecording}
                            className="flex justify-center"
                            whileHover={{ scale: 1.1 }}
                            // enter from below the screen
                            initial={{ y: 50 }}
                            animate={{ y: 0, delay: 0.5 }}
                            // exit to below the screen
                            exit={{ y: 50 }}
                        >
                            <div className="w-24 h-24 hover:bg-lime-500 cursor-pointer bg-lime-400 rounded-full shadow-md">
                                <img
                                    src={micIcon}
                                    alt="microphone"
                                />
                            </div>
                        </motion.div>}
                        {
                        gameManager.showScoreView == "question" && <motion.div
                     
                            className="flex justify-center"
                    
                            // enter from below the screen
                            initial={{ y: 50 }}
                            animate={{ y: 0, delay: 0.5 }}
                            // exit to below the screen
                            exit={{ y: 50 }}
                        >
                            <div className="w-24 h-24">
                              
                            </div>
                        </motion.div>}
                    {gameManager.showScoreView == 'score' && <motion.div
                        onClick={() => onHandleNext()}
                        className="flex justify-center"
                        whileHover={{ scale: 1.1 }} // Add a hover effect (optional)
                        // enter from right the screen
                        initial={{ y: 50 }}
                        animate={{ y: 0, delay: 1, duration: 1.1 }}
                    >
                        <div className="w-16 h-16 p-2 hover:bg-lime-500 cursor-pointer  bg-gray-200 rounded-full shadow-md flex justify-center items-center">
                            <HiArrowRight className="text-3xl text-white font-extrabold" />
                        </div>
                    </motion.div>}
                    {
                        gameManager.showScoreView == 'final_score' && <motion.div
                            className="flex justify-center"
                            whileHover={{ scale: 1.1 }} // Add a hover effect (optional)

                            initial={{ y: 100 }}
                            animate={{ y: 0, delay: 2, duration: 1.1 }}
                        >
                            <div className=" p-2 px-6 hover:bg-lime-500 cursor-pointer  bg-lime-300 rounded-full shadow-md flex flex-row items-center">
                                <span className="text-white font-extrabold flex flex-row">
                                    <HiOutlineMail className="text-3xl text-white font-extrabold mr-2" />
                                    <p className="pt-1"> Sent Report to Email </p>
                                </span>
                            </div>
                        </motion.div>
                    }
                    {
                        gameManager.showScoreView == 'final_score' && <motion.div
                            className="flex justify-center"
                            whileHover={{ scale: 1.1 }} // Add a hover effect (optional)

                            initial={{ y: 100 }}
                            animate={{ y: 0, delay: 2, duration: 1.1 }}
                            onClick={() =>{ 
                                console.log("exit")
                                // close this page
                                // navigate to home page
                                window.location.href = "/home";
                            } }
                        >
                            <div 
                  
                         
                                className=" p-2 px-6 hover:bg-orange-500 cursor-pointer  bg-orange-200 rounded-full shadow-md flex flex-row items-center">
                                <span className="text-white font-extrabold flex flex-row">
                                    <HiXCircle   className="text-3xl text-white font-extrabold mr-2" />
                                    <p className="pt-1"> Exit </p>
                                </span>
                            </div>
                        </motion.div>
                            
                    }
                </motion.div>
                <div className="w-full h-2 mt-6  flex flex-row">
                    {dataManager && dataManager.speechLabData && dataManager.speechLabData.phonics && dataManager.speechLabData.phonics[gameManager.currentQuestion] && dataManager.speechLabData.phonics[gameManager.currentQuestion].mic_duration > 0 &&
                        beginRecording &&
                        <>

                            <div className="w-1/2 h-ful bg-lime-500">
                                <motion.div
                                    initial={{ width: "0%" }} // Init 
                                    // animate={controlsLeft}
                                    animate={{ width: `${100}%` }}
                                    transition={{ duration: dataManager.speechLabData.phonics[gameManager.currentQuestion].mic_duration, delay: 0 }}
                                    className="h-full bg-white ">

                                </motion.div>
                            </div>
                            <div className="w-1/2 h-ful">
                                <motion.div
                                    initial={{ width: "100%" }}
                                    // animate={controls}
                                    animate={{ width: `${0}%` }}
                                    transition={{ duration: dataManager.speechLabData.phonics[gameManager.currentQuestion].mic_duration, delay: 0 }}
                                    className="h-full bg-lime-500 ">
                                </motion.div>
                            </div>
                        </>
                    }

                </div>
            </div>
        </div>
    );
};

export default SpeechLabController;
