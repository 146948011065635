import React, { useEffect } from 'react';
import { motion, useMotionValue, useTransform, animate } from "framer-motion";
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';

const CircularProgress = ({ score, delay, duration }) => {
    const count = useMotionValue(0);
    const rounded = useTransform(count, Math.round);

    useEffect(() => {
        if(!delay){
            delay = 1;
        }
        if(!duration){
            duration = 2.5;
        }
        const animation = animate(count, score, { duration: duration, delay : delay});
        return animation.stop;
    }, []);

    return (
        <div className="font-extrabold text-8xl text-lime-500 pl-10">
        <motion.span >
            {rounded}
        </motion.span>
        <span className='text-5xl'>%</span> 
        </div>
    )
}

export default CircularProgress;