
import { call, put } from 'redux-saga/effects';
import { sagaActions } from './saga_actions';
import { getAuthHeader } from '../services/auth_helper';
import config from '../config';


// sent wave file to server 'api/speech/play' post 
function* SPEECH_RECOGNITON (action) {
    try {
        const {audioBlob,word_ch } = action.payload;
        const url =  config.URI+'/api/speech/recognition';
        const header = getAuthHeader();

        const formData = new FormData();
        //  audioBlob to webm 
        // const webm = new Blob([audioBlob], { type: 'audio/webm' });
        formData.append('file',  audioBlob);
        formData.append('reference_text', word_ch);
        const boundary = Math.random().toString().substr(2);
        const response = yield call(fetch, url, { headers: {
            'authorization': header.Authorization,
            // 'content-type': `multipart/form-data; boundary=${boundary}`
        }, method: 'POST', body: formData });
        const data = yield response.json();
        console.log(data);
        yield put({ type: 'speechPlay/SET_SPEECH_LAB_SCORE', payload: data });
    } catch (e) {
        console.log(e);
    }
}

// get speech lab data by id : api/phonic/group/:id 
function* getSpeechLabData (action) {
    try {
        const url =  config.URI+'/api/phonic/group/'+action.payload;
        const headers = getAuthHeader();
        const response = yield call(fetch, url, { headers });
        const data = yield response.json();
        console.log(data);
   
    } catch (e) {
        console.log(e);
    }
}

function* INIT_SPEECH_PLAY (action) {
    const { mode, id } = action.payload;
    console.log(`INIT_SPEECH_PLAY ${mode} ${id}`);
    if(mode == 'casual') {
        console.log(`inside INIT_SPEECH_PLAY ${mode}`)
        const url =  config.URI+'/api/community/group/'+id;
        const headers = getAuthHeader();
        const response = yield call(fetch, url, {    "Content-Type": "application/json", });
        if(response.status == 200) {
            const data = yield response.json();
            yield put({ type: "speechPlay/SET_SPEECH_LAB_DATA", payload: data });
            yield put({type: "main/setLoading", payload: false});
        }else{
            // remove loading then popup message 'fail to load data'
            yield put({type: "main/setLoading", payload: false});
            yield put({ type: sagaActions.MESSAGE_POPUP, payload: { message: 'fail to load data', type: 'error' } });
        }
        
    }
    else if( mode == 'assignment') {
    
    }
}


export {
    SPEECH_RECOGNITON,
    getSpeechLabData,
    INIT_SPEECH_PLAY,
}