const _config = {
    dev: {
        URI: "http://localhost:2023",
    },
    production: {
        URI: "https://9taihjzpyq.ap-southeast-1.awsapprunner.com"
    },
    nutc: {
        URI: "https://w2g363ejxz.ap-southeast-1.awsapprunner.com"
    }
}
const TARGET = process.env.REACT_APP_TARGET || "dev";
const config = {
    defaultImage : "https://s3-ap-southeast-1.amazonaws.com/rgk-images/17tts_head_maxi_lc.png", 
    ..._config[TARGET],
}



export default config;