import React from "react";
import { useSelector, useDispatch } from "react-redux";

export default function LoadingPopup() {
    const { loading } = useSelector((state) => state.globalReducer);

    return (
    <>
        { loading &&
        <div className=" absolute w-screen h-screen flex flex-row  justify-between items-start z-50 ">
            <div className="w-full h-full flex justify-center items-center bg-slate-400 bg-opacity-20">
                <div className='felx flex-col justify-center items-center  text-center relative'>
                <div className="animate-spin rounded-full h-28 w-28 border-t-2 border-b-1 border-purple-500">
                    <image src="/images/logo.png" alt="logo" />
                </div>
                <img className=' absolute top-0' src="https://s3-ap-southeast-1.amazonaws.com/rgk-images/maxi_suit.png" alt="Face" />
                </div>
            </div>
        </div>}
    </>
    )
}

