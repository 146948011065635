import React, { useEffect } from 'react'
import { motion, AnimatePresence, useMotionValue, useTransform, animate } from "framer-motion";
import CircularProgress from './CircleProgress';
import { useSelector } from 'react-redux';
import { useState } from 'react';




export default function ScoreCard({ showScoreView, score }) {
    const {gameManager, dataManager} = useSelector(state => state.speechPlayReducer);
    const [text, setText] = useState("");
    useEffect(() => {
     
    if(gameManager.currentQuestion < 0  ) return;
      // loop through all dataManager.speechLabData.phonics[gameManager.currentQuestion].phonicSound
      // combine all text into one string > set to text
      let _text = "";
      if(dataManager.speechLabData.phonics.length >= gameManager.currentQuestion) return;
      dataManager.speechLabData.phonics[gameManager.currentQuestion].phonicSound.forEach(element => {
        _text += ` ${element.text}`;
      });
      setText(_text);
    }
    , [gameManager.currentQuestion]);
    
    return (

        <AnimatePresence>
            {
                gameManager.showScoreView == "score" && <motion.div
                    className="w-1/2 h-full  flex justify-center items-center "
                    initial={{ x: "100%", opacity: 0 }} // Initial position, hidden to the right
                    animate={{ x: 0, opacity: 1 }} // Final position, move to the left (in view)
                    transition={{ duration: 0.5, delay: 0.35 }} // Animation duration in seconds
                // exit={{ x: "-100%", opacity: 0 }}
                >

                    <motion.div className='w-3/5 h-3/5 flex flex-col justify-center items-center shadow-lg bg-slate-200 overflow-hidden  rounded-xl  text-center '>
                        <div className="px-4 w-full h-full flex flex-col  justify-center items-center bg-[url('https://s3-ap-southeast-1.amazonaws.com/rgk-images/bg_nana_space_game.png')] ">
                            <div className='w-4/6 h-full  flex text-center justify-center items-center '>
                                <CircularProgress score={dataManager.recordedData[gameManager.currentQuestion].NBest[0].AccuracyScore} />
                            </div>
                            <span className='pb-2 text-slate-100 font-bold opacity-60'>
                                {
                                    (dataManager.recordedData[gameManager.currentQuestion].NBest[0].Words[0]?.ErrorType && 
                                        dataManager.recordedData[gameManager.currentQuestion].NBest[0].Words[0]?.ErrorType !== 'None'  ) ? 
                                    dataManager.recordedData[gameManager.currentQuestion].NBest[0].Words[0].ErrorType : 
                                    "sound like native speaker"
                                }
                            
                            </span>   
                        </div>

                        <div className='flex flex-col justify-center w-full h-2/5 text-center py-4 rounded-bl-lg rounded-br-lg bg-white'>

                            <h5 className=" text-xl font-bold leading-none text-gray-900 dark:text-white">
                                {dataManager.recordedData[gameManager.currentQuestion].NBest[0].Display}
                            </h5>
                            <p className="text-lg font-medium text-gray-500 dark:text-gray-400">
                                {text}
                            </p>

                        </div>
                    </motion.div>
                </motion.div>}

        </AnimatePresence>
    )
}
