import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux';
import AnalyseCard from '../components/specchPlay/AnalyseCard';
import ScoreCard from '../components/specchPlay/SocreCard';
import SpeechLabImageCardComponent from '../components/specchPlay/SpeechLabImageCardComponent';
import WordCardComponent from '../components/specchPlay/WordCardComponent';
import SpeechLabController from '../components/specchPlay/SpeechLabController';
import SpeechLabProgressBar from '../components/specchPlay/SpeechLabProgressBar';
import ConfettiExplosion from 'react-confetti-explosion';
import FinalScoreCard from '../components/specchPlay/FinalScoreCard';
import { useSearchParams } from 'react-router-dom';
import LoadingPopup from '../components/LoadingPopup';
import { useDispatch } from 'react-redux';


export default function SpeecbLab() {
    const dispatch = useDispatch();
    const [percentage, setPercentage] = useState(0); // Initial percentage value
    const [isExploding, setIsExploding] = React.useState(false);
    const [showScoreView, setShowScoreView] = useState("question"); // question, score, final_socre
    // const recorderControls = useAudioRecorder()
    const addAudioElement = (blob) => {
        const url = URL.createObjectURL(blob);
        const audio = document.createElement("audio");
        audio.src = url;
        audio.controls = true;
        document.body.appendChild(audio);
    };
    const [status, setStatus] = useState("none"); // idle, recording, recorded    
    const [height, setHeight] = useState(null);
    
    // get speech play gameManager from redux store 
    const {gameManager, dataManager} = useSelector(state => state.speechPlayReducer);
    let {loading} = useSelector(state => state.globalReducer);
    useEffect(() => {
        const _height = window.innerHeight - 104;
        setHeight(_height);

    }, []);
    useEffect(() => {
        if (gameManager.showScoreView == "final_score" || gameManager.showScoreView == "score") {
            setTimeout(() => {
                setIsExploding(true);
            }, 500);
            setTimeout(() => {
                setIsExploding(false);
            }, 3000);

        }else{
            setIsExploding(false);

        }
    } 
    , [gameManager.showScoreView]);
   
    const [searchParams, setSearchParams] = useSearchParams();
    const id = searchParams.get("id");
    const from = searchParams.get("from");
    console.log(`param id : ${searchParams.get("id")}`);
    console.log(`param from: ${searchParams.get("from")}`);



    useEffect(() => {
        // dispatch evebt global loading to true 
        console.log(`id: ${id} : casual`)
        dispatch({type: "speechPlay/RESET"});
        dispatch({type: "main/setLoading", payload: true});
        dispatch({type: "INIT_SPEECH_PLAY", payload: {id: id, mode: "casual"}});

        if( from == "casual"){
        
            // event to init speechlab with casual mode
            // dispatch reset speech play 
           
            dispatch({type: "INIT_SPEECH_PLAY", payload: {id: id, mode: "casual"}});
        }else if(from && id && from == "assignment"){
            // event to init speechlab with assignment mode
        }
    }, []);


    return (
        <div className='flex flex-col h-screen border-t-2 overflow-hidden'>
            <SpeechLabProgressBar 
                showScoreView={showScoreView} percentage={gameManager.progress} />
            {
    
                <>
                <div className="flex justify-center px-10 pb-20" style={{ height: `${height}px` }}>
                   <SpeechLabImageCardComponent showScoreView={showScoreView}/>
                   <WordCardComponent showScoreView={showScoreView}/>
                   <ScoreCard showScoreView={showScoreView} score={80} />
                   <AnalyseCard showScoreView={showScoreView}/>
                   <FinalScoreCard showScoreView={showScoreView}/>
                </div>
                <SpeechLabController percentage={gameManager.progress} setPercentage={setPercentage} setShowScoreView={setShowScoreView}  showScoreView={showScoreView}/>
                {isExploding && 
                    <div className=" absolute w-screen h-screen flex flex-row  justify-between items-start   ">
                        <ConfettiExplosion />
                        <ConfettiExplosion />
                        <ConfettiExplosion />
                    </div>
                }
                <LoadingPopup/>
                </>
            }
        </div>
    )
}