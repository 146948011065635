
import { useNavigate } from 'react-router-dom';

import { useEffect, useState } from "react";
import { useDispatch } from 'react-redux';
import { LOGIN, CODE_LOGIN, REGISTER } from '../features/user/user_slice';
import { useSelector } from 'react-redux';
import { setPopup, closePopupEvent } from '../features/globalReducer';
import { Dropdown } from 'flowbite-react';

const SignUpPage = () => {
    // const [isScanQRCode, setIsScanQRCode] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassowrd] = useState('');
    const [isRemember, setIsRemember] = useState(false);
    const [pinCode, setPinCode] = useState('');
    const [branch, setBranch] = useState('');
    const [emailDomain, setEmailDomain] = useState('');
    const [showLoginView, setShowLoginView] = useState(true);
    const navigate = useNavigate();
    const handleLinkClick = () => () => {
        navigate('/home');
    };
    const school = {
        branchs: [ ]
    }
    const branchs = [
        {
            name : "Tampines Junction",
            code : "TMJ",
            _id : "650093efe046b19cf644f207"
        },
        {
            name : "Downtown East",
            code : "DTE",
            _id : "6500941be046b19cf644f20c"
        }
    ]

    const user = useSelector((state) => state.userReducer.user);

    useEffect(() => {
        if (user) {
            navigate('/home');
        }
    }, [user]);


    const dispatch = useDispatch();

    const handleLogin = () => {
        dispatch(LOGIN({
            email,
            password,
            isRemember
        }));
    }

    const handleRegister = () => {
        if (password !== confirmPassword) {
            dispatch(setPopup({ title: "!", message: `Password not match`, color: "yellow" }));

            return;
        }
        // email domain 
     
        if (emailDomain !== "galaxykids.ai") {
            dispatch(setPopup({ title: "!", message: `ADMIN ACCESS ONLY `, color: "red" }));
            return;
        }

        // if branch is not selected 
        if (!branch && school.branchs.length > 1) {
            dispatch(setPopup({ title: "!", message: `Please select your branch`, color: "yellow" }));
            return;
        }

        dispatch(REGISTER({
            email,
            password,
            branch: {...branch}
        }));
        
        dispatch(setPopup({ title: "Registering", message: `Processing`, color: "green" }));
    }



    const handleLoginWithPinCode = () => {
        console.log(`start oogin with pinCode: ${pinCode}`)
        if (pinCode != null && pinCode.length >= 4) {
            dispatch(CODE_LOGIN({ code: pinCode }));
        } else {
            // dispatch(setPopup({title: "Login Failed", message: `Please check your pin code`, color: "red"}));
        }
    }

    const updatePassword = (e) => {
        setPassword(e.target.value);
    }
    const updateConfirmPassword = (e) => {
        setConfirmPassowrd(e.target.value);
        if (e.target.value !== password) {
            console.log("not match");
            dispatch(setPopup({ title: "!", message: `Password not match`, color: "yellow" }));
        } else {
            dispatch(closePopupEvent());
        }
    }

    const updateEmail = (e) => {
        setEmail(e.target.value);
        const _emailDomain = e.target.value.split('@')[1];
        setEmailDomain(_emailDomain);
    }
    const css = 'flex flex-row justify-center items-center space-x-2 mb-4  rounded-lg shadow-md ' //bg-slate-100 

    return (
        <>
            <div className="flex justify-center items-center flex-col w-full h-full bg-[url('https://i.pinimg.com/564x/d8/1f/c3/d81fc314221b7143aea783e1d0f0dfa1.jpg')] bg-cover">
                <div className="relative w-full max-w-md max-h-full">
                    <h1 className=" font-extrabold mb-4">Login / Resigter</h1>
                    <div className={showLoginView ? `${css} bg-slate-100 ` : `${css} bg-white `} >

                        {showLoginView &&
                            <div className="justify-center items-center space-x-2  mt-4 ">
                                <div className="px-6 py-6 lg:px-8">
                                    <div className="space-y-6" >
                                        <div>
                                            <label for="pin" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">PIN CODE</label>
                                            <input type="password" value={pinCode} onChange={(e) => setPinCode(e.target.value)} name="pin" id="password" placeholder="PIN CODE" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white" />
                                        </div>
                                        <button onClick={handleLoginWithPinCode} className="w-full text-white bg-indigo-700 hover:bg-indigo-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                                            PIN Login
                                        </button>
                                        <button type="" className="w-full text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                                            Scan QR Code
                                        </button>
                                    </div>
                                </div>
                            </div>
                        }
                        <div className="relative bg-white  dark:bg-gray-700">
                            <button onClick={handleLinkClick()} type="button" className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white" data-modal-hide="authentication-modal">
                                <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                                <span className="sr-only">Close modal</span>
                            </button>
                            <div className="px-6 py-6 lg:px-8">
                                <div className="space-y-6">
                                    <div>
                                        <label for="email" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Your email</label>
                                        <input value={email} onChange={updateEmail} type="email" name="email" id="email" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white" placeholder="name@company.com" required />
                                    </div>
                                    {/* <div className='w-full'>
                                        { !showLoginView && emailDomain === "littleskoolhouse.com" &&
                                         <Dropdown label={branch ? branch.name : " centres"} className="w-full">
                                            {branchs.map((_branch) => (
                                                <Dropdown.Item onClick={() => setBranch(_branch)}>[{_branch.code}] {_branch.name}</Dropdown.Item>
                                            ))}
                                        </Dropdown>
                                        }
                                    </div> */}
                                    <div>
                                        <label for="password" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Your password</label>
                                        <input value={password} onChange={updatePassword} type="password" name="password" id="password" placeholder="••••••••" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white" required />
                                    </div>
                                    {!showLoginView &&
                                        <div>
                                            <label for="password" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Confirm password</label>
                                            <input value={confirmPassword} onChange={updateConfirmPassword} type="password" name="password" id="password" placeholder="••••••••" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white" required />
                                        </div>
                                    }
                                    {showLoginView &&
                                        <div className="flex justify-between">
                                            <div className="flex items-start">
                                                <div className="flex items-center h-5">
                                                    <input id="remember" type="checkbox" value={isRemember} className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-blue-300 dark:bg-gray-600 dark:border-gray-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800" required />
                                                </div>
                                                <label for="remember" className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Remember me</label>
                                            </div>
                                            <a href="#" className="text-sm text-blue-700 hover:underline dark:text-blue-500">Lost Password?</a>
                                        </div>
                                    }
                                    {showLoginView &&
                                        <button onClick={handleLogin} className="w-full text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                                            Login
                                        </button>
                                    }
                                    {!showLoginView &&
                                        <button onClick={handleRegister} className="w-full text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                                            Register
                                        </button>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    {showLoginView &&
                        <div className="text-sm font-medium text-gray-500 dark:text-gray-300">
                            Not registered? <a onClick={() => setShowLoginView(false)} className="text-blue-700 hover:underline dark:text-blue-500">Create account</a>
                        </div>
                    }
                    {!showLoginView &&
                        <div className="text-sm font-medium text-gray-500 dark:text-gray-300">
                            Already have account ? <a onClick={() => setShowLoginView(true)} className="text-blue-700 hover:underline dark:text-blue-500"> Login </a>
                        </div>
                    }
                </div>
            </div>
        </>
    );
};

export default SignUpPage;