import React, { useState, useEffect } from 'react'
import { motion, useAnimation } from "framer-motion";
import { useSelector } from 'react-redux';
import { fa } from '@faker-js/faker';

const SpeechLabProgressBar = ({showScoreView, percentage}) => {
    const {gameManager, dataManager} = useSelector(state => state.speechPlayReducer);
   const [isShow, setIsShow] = useState(true);
    const controls = useAnimation();
    useEffect(() => {
        // Animate the progress bar when the percentage changes
        controls.start({ width: `${percentage}%` });
    }, [percentage, controls]);

    useEffect(() => {
        // if gameManager.showScoreView == "final_score" then hide the progress bar with delay 3s
        // if(gameManager.showScoreView == "final_score"){
        //     setTimeout(() => {
        //         setIsShow(false);
        //     }, 3000);
        // }else{
        //     setIsShow(true);
        // }
    }
    , [gameManager.showScoreView]);

    return (
        <>
        
            {isShow && gameManager.currentQuestion >=0 &&
        <motion.div 
            initial={{ y: 50 }}
            animate={{ y: 0 , duration: 1.5}}
            // exit to below the screen
            exit={{ y: 150   ,delay: 1.5 }}
            className='felx w-full pt-5 px-20'>
            <div className="w-full bg-gray-200 rounded-full dark:bg-gray-700 shadow-md">
                <motion.div
                    className="relative h-7 bg-blue-600 text-center text-xs font-medium text-blue-100 p-0.5 leading-none rounded-full flex justify-end"
                    initial={{ width: "0%" }} // Initial width before animation
                    animate={controls}
                    transition={{ duration: 0.5 }} // Animation duration in seconds
                >
             
                    <div className="w-20 h-20 absolute flex flex-row justify-end align-middle items-center" style={{ top: "-28px", right: "-15px" }}>
                        <span className="pr-5 font-bold pt-1"> {gameManager.currentQuestion}/{dataManager.speechLabData.phonics.length}</span>
                        <img src="https://s3-ap-southeast-1.amazonaws.com/rgk-images/maxi_face_excited1.png" alt="Face" />
                    </div>
                </motion.div>
            </div>
        </motion.div>}
        </>
    );
}

export default SpeechLabProgressBar;