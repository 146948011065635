import React from "react";
import { motion, useMotionValue, useTransform, animate} from "framer-motion";
import { useEffect } from "react";




const FinalWordScoreCard = ({ image_uri ,word, score }) => {
    const count = useMotionValue(0)
    const rounded = useTransform(count, Math.round);

    const getScoreColour = (score) => {
        if (score > 70) {
            return "bg-lime-400 rounded-full h-2.5 dark:bg-lime-400";
        } else if (score > 30) {
            return "bg-yellow-400 rounded-full h-2.5 dark:bg-yellow-400";
        } else {
            return "bg-red-400 rounded-full h-2.5 dark:bg-red-400";
        }
    }
    useEffect(() => {
   
        const animation = animate(count, score, { duration: 2, delay: 1 });
        return animation.stop;
      }, [])

    return (
        <motion.li 
        whileTap={{ scale: 1.02 }}
        className="flex flex-row border justify-start items-center p-2 pr-4  cursor-pointer bg-white">
        <div className="ml-2 text-left w-32">
            <img className="w-16 h-16 rounded-full object-cover" src={image_uri} />
        </div>
        <div className="ml-2 text-left w-32">
            <span className="font-medium  text-md"> { word } </span>
        </div>
        <div className=" ml-4 mr-3 w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
            <motion.div 
                initial={{ width: 0 }}
                animate={{ width: `${score}%` }}
                transition={{ duration: 0.5, delay: 1.2 }}
            className={
                getScoreColour(score)
            }>
            </motion.div>
        </div>
        <motion.div className="ml-auto text-sm text-lime-400 font-bold">{rounded}</motion.div>
    </motion.li>
    )
}


export default FinalWordScoreCard;

