import React, { Component } from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faInfoCircle } from "@fortawesome/free-solid-svg-icons";

export default class ReportDifficaltyCard extends Component {

    constructor(props) {
        super(props);
        this.state = {
            report: this.props.report,
            index: this.props.index
        }
    }


    render() {
        return (
            <div className="items-center justify-center space-y-4 sm:flex sm:space-y-0 sm:space-x-4">
            <a href="#" className="block max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">
                <h5 className="mb-2 text-lg font-bold tracking-tight text-gray-900 dark:text-white"> {this.state.report.word} </h5>
                <div className="flex flex-row items-center" >
                    <FontAwesomeIcon icon={faCheckCircle} className="text-green-500" />
                    <p className="ml-3 text-sm text-gray-700 dark:text-gray-400">{parseFloat(this.state.report.averageScore).toFixed()}% correct</p>
                </div>
                <div className="flex flex-row items-center mt-2" >
                    <FontAwesomeIcon icon={faInfoCircle} className="text-sky-500" />
                    <p className="ml-3 text-sm text-gray-700 dark:text-gray-400">rank {this.state.index} </p>

                </div>
            </a>
        </div>
        )
    }
}


