export const sagaActions = {
    FETCH_LOGIN_SAGA: 'user/LOGIN',
    FETCH_TOKEN_LOGIN_SAGA: 'FETCH_TOKEN_LOGIN_SAGA',
    LOGGIN_SUCCEEDED : 'user/LOGGIN_SUCCEEDED',
    USER_LOGGIN_FAILED: 'user/LOGGIN_FAILED',
    MESSAGE_POPUP: 'main/setPopup',
    CODE_LOGIN: 'user/CODE_LOGIN',
    REGISTER: 'user/REGISTER',
    LOCATION_STATE: 'main/setLocation',
    TEACHER_GET_STUDY_GROUP: 'studyGroup/TEACHER_GET_STUDY_GROUP',
    HOME_INIT : 'home/initHomeData',
    SETSTUDENTS : 'home/setStudents',
    FETCH_STUDENTS: 'fetchStudentsData',
    FETCH_GEN_STUDENT: 'fetchGeneratedStudents',
    FETCH_UPDATE_STUDENT: 'FETCH_UPDATE_STUDENT',
    FETCH_STUDYGROUPS: 'FETCH_STUDYGROUPS', 
    FETCH_STUDENTS_BY_STUDYGROUP: 'FETCH_STUDENTS_BY_STUDYGROUP',
    FETCH_PHONICGROUP: 'FETCH_PHONICGROUP',
    CREATE_ASSIGNMENT: 'CREATE_ASSIGNMENT',
    FETCH_ASSIGNMENTS: 'FETCH_ASSIGNMENTS',
    SETASSIGNMENTS:"home/setAssignments",
    CREATE_STUDYGROUP: 'CREATE_STUDYGROUP',
    FETCH_ASSIGNMENT_RECORED: "FETCH_ASSIGNMENT_RECORED",
    FETCH_ASSIGNMENT_RECORED_BY_STUDENT_ID_AND_ASSIGNMENT_ID: "FETCH_ASSIGNMENT_RECORED_BY_STUDENT_ID_AND_ASSIGNMENT_ID",
    FETCH_OFFICAL_PHONICS: "FETCH_OFFICAL_PHONICS",
    FETCH_OFFICAL_PHONICS_GROUP : "FETCH_OFFICAL_PHONICS_GROUP",
    FETCH_TEACHER_PHONICS_GROUP : "FETCH_TEACHER_PHONICS_GROUP",
    FETCH_TEACHER_PHONICS : "FETCH_TEACHER_PHONICS",
    CREATE_PHONIC : "CREATE_PHONIC",
    CREATE_PHONIC_GROUP : "CREATE_PHONIC_GROUP",
    UPDATE_PHONIC_GROUP : "UPDATE_PHONIC_GROUP",
    INIT_SPEECH_LAB : "INIT_SPEECH_LAB",
    SEARCH_OFFICAL_PHONICS : "SEARCH_OFFICAL_PHONICS",
    SET_ARCHIVE_ASSIGNMENT : "SET_ARCHIVE_ASSIGNMENT",
    FETCH_PHONIC_PUBLIC: "FETCH_PHONIC_PUBLIC",
    ADD_EXIST_STUDENT_BY_CODE: "ADD_EXIST_STUDENT_BY_CODE",
    INIT_SPEECH_PLAY: "INIT_SPEECH_PLAY",
    SPEECH_RECOGNITON: "SPEECH_RECOGNITON"  
  }