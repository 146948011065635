'use client';
import './App.css';
import Navbar from './components/NavBar.js';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from './pages/home.js';
import Quizes from './pages/quizes';
import SignUpPage from './pages/SignUp';
import PopupMessage from './components/PopupMessage.jsx';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import SpeecbLab from './pages/SpeecbLab';
import WaterMark from './components/WaterMark.jsx';
import Wiki from './pages/Wiki';

// use reducer to manage state of the app 
import { Provide } from 'react-redux'
import store from './redux/store'




function App() {
  const dispatch = useDispatch();
  let path = window.location.pathname;
  const loginStatus = useSelector((state) => state.userReducer.loginStatus);
 

    // LOGGIN_FAIL
    useEffect(() => {
      console.log(`loginStatus :  ${loginStatus}`);
      // if page is not login page and loginStatus is false, redirect to login page
      if ((path !== "/login" && loginStatus === false) && path !== "/speechlab") {
         window.location.href = "/login";
      }
      console.log(`redirect to login`)
    }, [loginStatus]);

       // FETCH_TOKEN_LOGIN_SAGA 
       useEffect(() => {
        console.log("FETCH_TOKEN_LOGIN_SAGA");
        dispatch({ type: "FETCH_TOKEN_LOGIN_SAGA" });
      }, []);

    // listenn to event LOGGIN_FAIL

  return (
    <div className='h-screen overflow-hidden'>
         <PopupMessage />
      <BrowserRouter>
        <Navbar></Navbar>
        <Routes>
          <Route path="/">
            <Route path="" element={<SignUpPage />} />
            <Route path="login" element={<SignUpPage />} />
            <Route index path='home' element={<Home />} />
            <Route path="quizes" element={<Quizes />} />
            <Route path="speechlab" element={<SpeecbLab />} />
            <Route path='wiki' element={<Wiki/>} />
            <Route path="*" element={<h1>Not Found</h1>} />

          </Route>
        </Routes>
      </BrowserRouter>
   
      {/* <WaterMark/> */}
    </div>
  );
}

export default App;
