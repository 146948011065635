import React, { useState, useEffect } from "react";
import StatusView from "../home//studentTabViews/TeacherStatus";
import { useSelector, useDispatch } from "react-redux";
import { setCurrentStudyGroup } from "../../features/home/homeSlice";
import { HiPlusCircle } from 'react-icons/hi';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSchool, faSchoolFlag } from "@fortawesome/free-solid-svg-icons";
import { SiGoogleclassroom } from 'react-icons/si';


const SumariesView = () => {
    const { studyGroups } = useSelector(state => state.homeReducer);
    const { user } = useSelector(state => state.userReducer);
    const [selectedStudyGroup, setSelectedStudyGroup] = useState(null);
    const currentStudyGroup = useSelector(state => state.homeReducer.currentStudyGroup);
    const [openModal, setOpenModal] = useState(undefined);
    const dispatch = useDispatch();
    function handleSelectGroup(index) {
        setSelectedStudyGroup(studyGroups[index]);
        setCurrentStudyGroup(studyGroups[index]);
        // set current study group to studyGroups[index] 
        // dispatch home/setCurrentStudyGroup  to studyGroups[index]
        dispatch({ type: 'home/setCurrentStudyGroup', payload: studyGroups[index] });
        dispatch({ type: 'FETCH_STUDENTS_BY_STUDYGROUP', payload: studyGroups[index]._id })
        setOpenModal(undefined)
    }

    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [level, setLevel] = useState("");
    function onClickCreateStudyGroup() {

        console.log(`create study group ${title} ${description} ${level}`);
        // dispatch create study group 
        dispatch({ type: "CREATE_STUDYGROUP", payload: { title: title, description: description, level: level } })

        //dispatch popup message comming soon
        // dispatch({ type: 'main/setPopup', payload: { title: "Coming soon", message: "This feature is coming soon", color: "red" } });
        setOpenModal(undefined)

    }

    // function updateTitle 
    const updateTitle = (e) => {

        setTitle(e.target.value)
    }

    const onClickSelectClass = () => {
        setOpenModal('selectClass');
    }

    // on start if studyGroups is not empty and currentStudyGroup is null 
    // set currentStudyGroup to studyGroups last element
    useEffect(() => { 
        if(currentStudyGroup != null){
            // find currentStudyGroup in studyGroups
            const index = studyGroups.findIndex((studyGroup) => studyGroup._id == currentStudyGroup._id);
            if(!index >= 0){
                handleSelectGroup(index );
            }
        }
        else if (studyGroups.length > 0 ) {
            handleSelectGroup(studyGroups.length - 1);
        }
    }, [studyGroups]);

    return (
        <>
            <div className="flex justify-center align-middle items-center px-5 py-5  w-full">
                <ol className="flex items-center w-full ">
                    <li className="flex w-full items-center text-blue-600 dark:text-blue-500 after:content-[''] after:w-full after:h-1 after:border-b after:border-blue-100 after:border-4 after:inline-block dark:after:border-blue-800">
                        <span className="flex items-center justify-center w-auto h-10 bg-blue-100 rounded-full lg:h-12  dark:bg-blue-800 shrink-0 px-5">
                            <button data-dropdown-toggle="dropdownAvatarName" className="flex items-center text-sm font-medium text-gray-900 rounded-full hover:text-blue-600 dark:hover:text-blue-500 md:mr-0  focus:ring-gray-100 dark:focus:ring-gray-700 dark:text-white" type="button">

                                <FontAwesomeIcon icon={faSchool} className="w-5 h-5 mr-2" />
                                {user?.school && user?.school != "none" ? user.school : "littleskoolhouse"} 
                                { user?.branch &&
                                    <span>  { ` : ${user.branch_code}`} </span> 
                                }
                            </button>
                        </span>
                    </li>
                    {  false &&
                    <li className="flex w-full items-center text-blue-600 dark:text-blue-500 after:content-[''] after:w-full after:h-1 after:border-b after:border-blue-100 after:border-4 after:inline-block dark:after:border-blue-800">
                        <span className="flex items-center justify-center w-auto h-10 bg-blue-100 rounded-full lg:h-12  dark:bg-blue-800 shrink-0 px-5">
                            <button data-dropdown-toggle="dropdownAvatarName" className="flex items-center text-sm font-medium text-gray-900 rounded-full hover:text-blue-600 dark:hover:text-blue-500 md:mr-0  focus:ring-gray-100 dark:focus:ring-gray-700 dark:text-white" type="button">
                                <FontAwesomeIcon icon={faSchoolFlag} className="w-5 h-5 mr-2" />
                                teachers
                            </button>
                        </span>
                    </li>
}
                    <li onClick={onClickSelectClass}
                        className="flex w-full items-center text-blue-600 dark:text-blue-500 after:content-[''] after:w-full after:h-1 after:border-b after:border-blue-100 after:border-4 after:inline-block dark:after:border-blue-800">
                        <span className=" flex items-center justify-center w-auto h-10 bg-blue-100 rounded-full lg:h-12  dark:bg-blue-800 shrink-0 px-5">
                            <button id="dropdownAvatarNameButton" data-dropdown-toggle="dropdownAvatarName" className="flex items-center text-sm font-medium text-gray-900 rounded-full hover:text-blue-600 dark:hover:text-blue-500 md:mr-0  focus:ring-gray-100 dark:focus:ring-gray-700 dark:text-white" type="button">
                                <SiGoogleclassroom className="w-5 h-5 mr-2" />
                                {selectedStudyGroup ? selectedStudyGroup.title : "Select a class"}
                            </button>
                        </span>
                    </li>
                    <li className="flex items-center w-full">
                        <span className="flex items-center justify-center w-10 h-10 bg-gray-100 rounded-full lg:h-12 lg:w-12 dark:bg-gray-700 shrink-0">
                            <svg className="w-4 h-4 text-gray-500 lg:w-5 lg:h-5 dark:text-gray-100" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 18 20">
                                <path d="M16 1h-3.278A1.992 1.992 0 0 0 11 0H7a1.993 1.993 0 0 0-1.722 1H2a2 2 0 0 0-2 2v15a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2ZM7 2h4v3H7V2Zm5.7 8.289-3.975 3.857a1 1 0 0 1-1.393 0L5.3 12.182a1.002 1.002 0 1 1 1.4-1.436l1.328 1.289 3.28-3.181a1 1 0 1 1 1.392 1.435Z" />
                            </svg>
                        </span>
                    </li>
                </ol>
            </div>
            {
                openModal === 'selectClass' &&
                <div
                   
                    className="absolute top-0 left-0 w-full h-full bg-gray-900 bg-opacity-50 z-10">
                    <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white rounded-lg shadow-lg w-80">
                        <div className="p-4 border-b">
                            <h2 className="text-xl font-semibold text-gray-800">Select a class</h2>
                            <p className="text-sm text-gray-400">Select a class to view data</p>
                        </div>
                        {studyGroups.map((studyGroup, index) => (
                            <div
                                onClick={() => handleSelectGroup(index)}
                                className={
                                    (currentStudyGroup?.title == studyGroup.title) ?
                                        "p-2 border-t text-blue-500 cursor-default" :
                                        "p-2 border-t hover:bg-slate-400 hover:text-white cursor-pointer"
                                }>
                                <div className="flex items-center justify-between cursor-pointer ">

                                    <div className="flex items-center">
                                        <span className="inline-block h-6 w-6 rounded-full overflow-hidden bg-gray-100">
                                            {/* <svg className="h-full w-full text-gray-300" fill="currentColor" viewBox="0 0 24 24">
                                            </svg> */}
                                            { studyGroup?.img_url &&
                                                <img className="h-full w-full text-gray-300" src={studyGroup?.img_url} />
                                            }
                                            {
                                                !studyGroup?.img_url &&
                                                <svg className="h-full w-full text-gray-300" fill="currentColor" viewBox="0 0 24 24"/>
                                            }
                                        </span>
                                        <p className="ml-2 text-sm font-medium">{studyGroup.title}</p>
                                    </div>
                                </div>
                            </div>
                        ))}
                        <div 
                            onClick={() => { setOpenModal('addNewGroup');  }}
                            className="p-2 border-t hover:bg-green-500 text-green-500 hover:text-slate-50 cursor-pointer">
                            <div className="flex items-center justify-center ">
                                <span className="inline-block h-6 w-6 rounded-full overflow-hidden">
                                    <HiPlusCircle className="h-full w-full" />
                                </span>
                                <p className="ml-2 text-sm font-bold "> Create New Class</p>
                            </div>
                        </div>
                    </div>
                </div>
            }
            {openModal === 'addNewGroup' &&
                <div className=" absolute  h-full w-screen flex flex-col justify-center items-center align-middle z-10 top-0 bg-zinc-500 bg-opacity-70 ">
                    <div className=" h-fit w-fit flex justify-center items-center align-middle bg-white p-5 rounded-md shadow-sm  flex-col">
                        <div>
                            <label for="title" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Group Name</label>
                            <input
                                onChange={updateTitle}
                                type="text"
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                placeholder="Group name" />
                        </div>
                        <div>
                            <label for="Description" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Description</label>
                            <input
                                onChange={(e) => { setDescription(e.target.value) }}
                                type="text"
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                placeholder="Description" />
                        </div>
                        <div>
                            <label for="Description" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Level</label>
                            <input
                                onChange={(e) => { setLevel(e.target.value) }}
                                type="text"
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                placeholder="Level" />
                        </div>
                        <button onClick={() => onClickCreateStudyGroup()}
                            className="mt-2  text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                            Create
                        </button>
                        <button onClick={() => setOpenModal(undefined)} className=" mt-2 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                            Cancel
                        </button>

                    </div>
                </div>}
        </>
    );
}

export default SumariesView;